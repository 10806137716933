.settings-wrapper {
  padding: 4%;
}

.settings-card {
  background-color: white;
  width: 100%;
  padding: 2% 4%;
  margin: auto;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
}

.settings-title h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 1000;
  color: var(--dashboard-text-color-1);
}

.import-btn-section{

  text-align: right;
  margin: 1% 0%;
}

.import-btns button{

  margin-left: 2%;
}

.modal-section-loader{
  text-align: center;
  margin: 10% 0%;
}

.modal-section{
  padding: 4% 0%;
  display: flex;
  flex-wrap: wrap;
}

.modal-sheets{
  flex-basis: 50%;
  display: flex;
  margin: 1% 0%;
  align-items: center;
}

.modal-buttons{
  display: flex;
  justify-content: end;
  margin: 2% 0%;
}

.modal-buttons button{

  margin: 1% 2%;
}

.error-importig-sheets{

  margin: 10% 0%;
  text-align: center;
}