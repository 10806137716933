.dashboard-container {
  padding: 1% 4%;
  width: auto !important;
}

.chart-wrapper {
  width: 97.5%;
  padding: 3%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.chart-wrapper canvas{
  height: 330px !important;
}

.sorter-div{

    text-align: right;
    color: #aaa;
}

.select-sort-value{

    border: none ;
    font-size: 16px;
    margin: 0% 0.5%;
    background-color: initial;
}

.events-display {
  width: 97.5% !important;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.upcoming-events-container {
  padding: 3%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  flex-basis: 48%;
}

.upcoming-events {
  display: flex;
  color: var(--upcoming-event-color);
}

.upcoming-events h3 {
  display: inline-block;
  margin-left: 2%;
}

.upcoming-events-list {
  margin-top: 5%;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin: 2% 0%;
  align-items: baseline;
  color: var(--dashboard-text-color-1);
}

.list-item small {
  font-size: 12px;
}

.list-item p {
  font-size: 14px;
}

.vector-line {
  height: 0.5px;
  flex-grow: 0;
  background-color: #c2cfe0;
}

.read-more{

  text-align: right;
  margin-top: 5%;
}

.read-more a{
  
  color: var(--dashboard-text-color-2);
}

.events-customers {
  flex-basis: 49%;
  display: flex;
  flex-direction: column;
}

.total-customers {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  margin-bottom: 2%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-2);
}

.total-events {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  margin-top: 2%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-2);
}

.events-customers small {
  color: var(--dashboard-text-color-1);
  font-size: 10px;
}

.right-side-items{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.griditems-link-wrapper {
  text-decoration: none;
  color: black;
}

.grid-right-items {
  width: 90% !important;
  margin-left: 8%;
  padding: 4% 9%;
  text-align: left;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-1);
}

.grid-right-items p {
  margin: 1% 0%;
  font-size: 16px;
  line-height: 1.5;
}

.grid-right-items h3 {
  margin: 1% 0%;
  color: var(--dashboard-text-color-2);
  font-size: 24px;
  line-height: 1.3;
}

.grid-right-items small {
  font-size: 10px;
}

.right-item-value {
  color: var(--dashboard-text-color-2);
}

.up-arrow {
  color: var(--dashboard-up-arrow);
  margin: 1% 3%;
}

.down-arrow {
  color: var(--dashboard-down-arrow);
  margin: 1% 3%;
}

