.calendar-wrapper {
  margin: 3% 4%;
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
}

.calendar-div {
  margin: 1% 4%;
  padding-bottom: 3%;
}

/* Buttons on top of calendar */

.events-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0% 3%;
  padding-top: 3%;
}

.events-buttons button {
  margin: 1%;
  padding: 0.5% 2%;
  background-color: var(--upcoming-event-color);
  border: none;
  font-weight: 600;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.events-buttons button:hover {
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--upcoming-event-color);
}

.button-1 {
  border: none;
  background-color: white !important;
  color: var(--upcoming-event-color) !important;
}

.button-1:hover {
  background-color: white;
  box-shadow: none !important;
}

/* Pop Up Modal CSS */

.popup-modal h2 {
  margin: 4% 6%;
}

.popup-title-div {
  display: flex;
  justify-content: space-between;
}

.popup-title-div span {
  margin: 2%;
  cursor: pointer;
  color: var(--sidebar-email-text-color);
}

.popup-form-wrapper {
  padding: 4%;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.textfield-wrapper {
  margin: 1% !important;
}

.textfield-wrapper label {
  margin: 2% 0% !important;
  color: var(--upcoming-event-color);
  font-weight: 600;
}

.textfield-input {
  padding: 3.5% 4%;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--input-border);
  background-color: var(--input-background);
}

.textfield-input:focus {
  outline: 2px solid var(--upcoming-event-color) !important;
}

.submit-button {
  margin: 2% 1%;
  padding: 3%;
  border-radius: 8px;
  border: none;
  background-color: var(--upcoming-event-color);
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 12px 0 rgba(55, 81, 255, 0.24);
}

.submit-button-wrapper{
  
  display: flex;
  align-items: center !important;
}

.MuiSlider-track {
  background-color: linear-gradient("#1eff00","#60f400","#bcc200","#cbb500","#ff3500","#ff0000") !important;
}
