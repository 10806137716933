@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url('https://css.gg/menu.css');

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}
.app-container-1 {
  display: flex;
  background-color: var(--background-color);
  height: 92vh;
}

.app-container-active{
  background-color: var(--background-color);
  display: block;
}

.side-navbar-container{

  display: inherit;
  transition: all 0.5s ease-in-out;
  width: 25%;
}

.side-navbar-container-position{

  display: block;
  position: absolute;
}

.side-navbar-container-display{

  transition: all 0.5s ease-in-out;
  width: 20%;
  display: block ;
}


/* ------------------ vertical side bar -------------- */

.side-menu {
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.06) 6px 0px 18px 0px;
  transition: width 0.3s ease-in;
  /* position: fixed; */
  height: 92vh;
  width: 100%;
  z-index: 1;
}

.top-section {
  display: flex;
  align-items: center;
  padding: 6% 9%;
  justify-content: space-between;
  color: var(--upcoming-event-color);
}

/* ------------- Sidebar Profile  ---------- */

.sidebar-profile-section {
  display: flex;
  flex-direction: column;
  margin-left: 11%;
  margin-top: 8%;
  margin-bottom: 10%;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}

.sidebar-profile-image {
  width: 48px;
  border-radius: 29px;
}

.Sidebar-profile-name {
  margin-bottom: 0%;
  color: var(--upcoming-event-color);
  font-family: "Poppins", sans-serif;
  font-size: 87.5%;
  font-weight: 600;
}

.Sidebar-profile-email {
  margin-bottom: 0%;
  color: var(--sidebar-email-text-color);
  font-family: "Poppins", sans-serif;
  font-size: 90%;
}

.sidebar-close{
  visibility: hidden;
}

/* ------------- Menu Items ---------- */

.sideMenu-items {
  margin-bottom: 8%;
  text-decoration: none;
}

.sideMenu-items a{
    text-decoration: none;
  }

.menu-item-text {
  color: var(--dashboard-text-color-1);
  margin-bottom: 0%;
  font-size: 87.5%;
  margin-left: 4%;
  font-family: "Poppins", sans-serif;
}

.menu-icon-color {
  color: var(--icon-color);
}

.Both-icon-text-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 6% 0% 6% 10%;
}

/* bottom language select */

.language {
  color: var(--icon-color);
  display: flex;
  align-items: flex-start;
  margin: 5% 1%;
}

.language svg {
  margin: 0% 5%;
}

.language h4 {
  color: var(--dashboard-text-color-1);
}

.language h6 {
  color: var(--icon-color);
  font-size: 13px;
}

/* for splitting the dasboard in two parts */

.division-class {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* horizontal divider in the side bar */

.side-nav-divider {
  height: 1px;
  background-color: var(--icon-color);
}

.side-nav-item-selected{

    color: var(--upcoming-event-color) !important;
}

/* Language Select highlight */

.language-selected{

  color: var(--dashboard-text-color-1);
}

.language h6 span{
  
  cursor: pointer;
}

/* Logout button */

.logout-div{

  display: flex;
  align-items: center;
  justify-content: right;
  margin: 1% 5%;
}

.logout-button{
  
  color: var(--dashboard-text-color-1) !important;
}

/* SideBar Media Queries */
@media (max-width: 880px) {
  /* .side-navbar-container { */
    /* display: none; */
    /* position: absolute; */
  /* } */
  .app-container {
    display: block;
  }
  .side-menu{
    width: 180px;
    z-index: 10;
  }
  .sidebar-close{
    visibility: visible;
  }
  .app-container-1{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1100px) {
  /* .side-navbar-container {
    display: none;
    position: absolute;
  } */
  .app-container-1{
    grid-template-columns: 1fr;
  }
  .sidebar-close{
    visibility: visible;
  }

  /* .side-navbar-container{
    display : none
  }
  .side-navbar-container-display{
    display: none;
  } */
}

@media (max-width: 1080px) {
  .sidebar-profile-section {
    flex-direction: column;
    margin-left: 0%;
    text-align: center;
  }
  .Sidebar-profile-name {
    margin: auto;
  }
  .Sidebar-profile-email {
    margin: auto;
  }
  .sideMenu-item-container1{
  display: none;
  }
}

@media (max-width:800px) {
  .app-container-1{
    display: flex;
  }
  .sideMenu-items1{
    display:flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 0%;
  }
  .sideMenu-item-container1{
    justify-content: space-around;
    display: flex;
    width: 100%;
  }

  /* .side-navbar-container{
    display : none
  }
  .side-navbar-container-display{
    display: none;
  }
  .horizontal-menu{
    padding: 1% !important;
  } */

}

/* @media (min-width:800px) {
  .show-navbar-icon{
    display: none;
  }
} */

.gg-chevron-double-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  cursor: pointer;
 }
 
 .gg-chevron-double-right::after,
 .gg-chevron-double-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  right: 6px
 }
 
 .gg-chevron-double-right::after {
  right: 11px
 } 

 .gg-chevron-double-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px
 }
 
 .gg-chevron-double-left::after,
 .gg-chevron-double-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  left: 6px
 }
 
 .gg-chevron-double-left::after {
  left: 11px
 } 