.login-wrapper {
  padding: 10%;
  background-color: var(--dashboard-text-color-1);
}

.login-card {
  background-color: white;
  padding: 2% 4% 2% 4%;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: auto;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
}

.login-card h1 {
  text-align: center;
  font-size: 72px;
  font-weight: bold;
  color: var(--upcoming-event-color);
}

.login-card h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 1000;
}

.login-inputs {
  margin: 6% 0%;
}

.login-input-label {
  margin: 1% 0% !important;
  color: var(--sidebar-email-text-color);
  font-weight: 500;
  font-size: 14px;
}

.password-labels{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  line-height: 1;
}

.forgot-pass-label{
  margin: 2% 0% !important;
  color: var(--sidebar-email-text-color);
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
}

.input-field {
  margin-bottom: 5% !important;
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--input-border);
  background-color: var(--input-background);
}

.input-field input{

  padding: 14.5px 14px;
}

.input-field .Mui-focused fieldset{
  border-color: var(--upcoming-event-color) !important;
}

.login-button-wrapper p{

  text-align: center;
  color: var(--sidebar-email-text-color);
  cursor: pointer;
}

.login-button{
  width: 100%;    
  margin: 1% 0% 4% 0% !important;
  padding: 3% 3% !important;
  background-color: var(--upcoming-event-color) !important;
  color: white !important;
  box-shadow: 0 4px 12px 0 rgba(55, 81, 255, 0.24);
}

.login-button:hover{

  background-color: #0088dd !important;
}

.linear-progress{
  margin-bottom: -2%;
  position: relative;
  z-index: 1;
}

@media(max-width:768px){
  .login-card{
    width: 60%;
  }
}

@media(max-width:550px){
  .login-card{
    width: 100%;
  }
}