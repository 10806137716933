@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://css.gg/menu.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://css.gg/user.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --text-color: #323C47 ;
  --icon-color: #C2CFE0 ;
  --background-color: #f5f6f8;
  --dashboard-text-color-1: #334d6e;
  --dashboard-text-color-2: #00557b;
  --dashboard-items-background: #fff;
  --sidebar-email-text-color: #90a0b7;
  
  /* arrow color */
  --dashboard-up-arrow: #2ed47a;
  --dashboard-down-arrow: #ff3c5f;

  /* Events color */
  --upcoming-event-color: #109cf1;

  /* Events Table Colors */
  --table-head-row: #808692;

  /* --input colors */
  --input-background: #fcfdfe;
  --input-border: #f0f1f7;
}

.divider{
  height: 1.5px;
  flex-grow: 0;
  background-color: #ebeff2;
}

a{
  text-decoration: none;
  color: inherit;
}

.snackbar-success .MuiSnackbarContent-root {
  background-color: #34c529 !important;
}

.snackbar-failure .MuiSnackbarContent-root {
  background-color: #ff5050 !important; 
}

.snackbar-info .MuiSnackbarContent-root {
  background-color: #008ebd !important; 
}

/* .app-container{

    display: grid;
    grid-template-columns: 0.25fr 1fr;
    background-color: var(--background-color);
} */

.navbar-container {
  width: 100%;
  /* height: 92vh; */
  overflow-y: auto;
}
.sk-fading-circle {
  margin: 0px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--upcoming-event-color);
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}


/* hamburger and notification bar container Media Queries */

@media(max-width:880px){

    .ham-container .fa-bars{

        visibility: visible !important;
    }

    .searchBar-bellIcon-container{

        margin: 1% 0%;
    }
}

/* Chart and Events Media Queries */

@media (max-width: 1100px) {
  .dashboard-container {
    flex-direction: column;
  }

  .right-side-items {
    max-width: 100% !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3% !important;
    align-items: center;
  }
  .grid-right-items {
    margin-left: 0%;
    margin: auto;
  }

  .chart-event-holder {
    width: 100% !important;
  }

  .chart-wrapper {
    margin: auto;
  }
  .events-display {
    margin: auto;
    margin-top: 4%;
  }
}

@media (min-width: 600px) {
  .chart-event-holder {
    max-width: 100% !important;
  }
}

@media (max-width: 430px) {
  .right-side-items {
    grid-template-columns: 1fr 1fr;
  }
  .events-display {
    display: grid;
    grid-template-columns: 1fr;
  }
  .events-customers {
    margin: 4% 0%;
  }
}

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}
.app-container-1 {
  display: flex;
  background-color: var(--background-color);
  height: 92vh;
}

.app-container-active{
  background-color: var(--background-color);
  display: block;
}

.side-navbar-container{

  display: inherit;
  transition: all 0.5s ease-in-out;
  width: 25%;
}

.side-navbar-container-position{

  display: block;
  position: absolute;
}

.side-navbar-container-display{

  transition: all 0.5s ease-in-out;
  width: 20%;
  display: block ;
}


/* ------------------ vertical side bar -------------- */

.side-menu {
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.06) 6px 0px 18px 0px;
  transition: width 0.3s ease-in;
  /* position: fixed; */
  height: 92vh;
  width: 100%;
  z-index: 1;
}

.top-section {
  display: flex;
  align-items: center;
  padding: 6% 9%;
  justify-content: space-between;
  color: var(--upcoming-event-color);
}

/* ------------- Sidebar Profile  ---------- */

.sidebar-profile-section {
  display: flex;
  flex-direction: column;
  margin-left: 11%;
  margin-top: 8%;
  margin-bottom: 10%;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}

.sidebar-profile-image {
  width: 48px;
  border-radius: 29px;
}

.Sidebar-profile-name {
  margin-bottom: 0%;
  color: var(--upcoming-event-color);
  font-family: "Poppins", sans-serif;
  font-size: 87.5%;
  font-weight: 600;
}

.Sidebar-profile-email {
  margin-bottom: 0%;
  color: var(--sidebar-email-text-color);
  font-family: "Poppins", sans-serif;
  font-size: 90%;
}

.sidebar-close{
  visibility: hidden;
}

/* ------------- Menu Items ---------- */

.sideMenu-items {
  margin-bottom: 8%;
  text-decoration: none;
}

.sideMenu-items a{
    text-decoration: none;
  }

.menu-item-text {
  color: var(--dashboard-text-color-1);
  margin-bottom: 0%;
  font-size: 87.5%;
  margin-left: 4%;
  font-family: "Poppins", sans-serif;
}

.menu-icon-color {
  color: var(--icon-color);
}

.Both-icon-text-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 6% 0% 6% 10%;
}

/* bottom language select */

.language {
  color: var(--icon-color);
  display: flex;
  align-items: flex-start;
  margin: 5% 1%;
}

.language svg {
  margin: 0% 5%;
}

.language h4 {
  color: var(--dashboard-text-color-1);
}

.language h6 {
  color: var(--icon-color);
  font-size: 13px;
}

/* for splitting the dasboard in two parts */

.division-class {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* horizontal divider in the side bar */

.side-nav-divider {
  height: 1px;
  background-color: var(--icon-color);
}

.side-nav-item-selected{

    color: var(--upcoming-event-color) !important;
}

/* Language Select highlight */

.language-selected{

  color: var(--dashboard-text-color-1);
}

.language h6 span{
  
  cursor: pointer;
}

/* Logout button */

.logout-div{

  display: flex;
  align-items: center;
  justify-content: right;
  margin: 1% 5%;
}

.logout-button{
  
  color: var(--dashboard-text-color-1) !important;
}

/* SideBar Media Queries */
@media (max-width: 880px) {
  /* .side-navbar-container { */
    /* display: none; */
    /* position: absolute; */
  /* } */
  .app-container {
    display: block;
  }
  .side-menu{
    width: 180px;
    z-index: 10;
  }
  .sidebar-close{
    visibility: visible;
  }
  .app-container-1{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1100px) {
  /* .side-navbar-container {
    display: none;
    position: absolute;
  } */
  .app-container-1{
    grid-template-columns: 1fr;
  }
  .sidebar-close{
    visibility: visible;
  }

  /* .side-navbar-container{
    display : none
  }
  .side-navbar-container-display{
    display: none;
  } */
}

@media (max-width: 1080px) {
  .sidebar-profile-section {
    flex-direction: column;
    margin-left: 0%;
    text-align: center;
  }
  .Sidebar-profile-name {
    margin: auto;
  }
  .Sidebar-profile-email {
    margin: auto;
  }
  .sideMenu-item-container1{
  display: none;
  }
}

@media (max-width:800px) {
  .app-container-1{
    display: flex;
  }
  .sideMenu-items1{
    display:flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 0%;
  }
  .sideMenu-item-container1{
    justify-content: space-around;
    display: flex;
    width: 100%;
  }

  /* .side-navbar-container{
    display : none
  }
  .side-navbar-container-display{
    display: none;
  }
  .horizontal-menu{
    padding: 1% !important;
  } */

}

/* @media (min-width:800px) {
  .show-navbar-icon{
    display: none;
  }
} */

.gg-chevron-double-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--ggs,1));
          transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  cursor: pointer;
 }
 
 .gg-chevron-double-right::after,
 .gg-chevron-double-right::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 7px;
  right: 6px
 }
 
 .gg-chevron-double-right::after {
  right: 11px
 } 

 .gg-chevron-double-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--ggs,1));
          transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px
 }
 
 .gg-chevron-double-left::after,
 .gg-chevron-double-left::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 7px;
  left: 6px
 }
 
 .gg-chevron-double-left::after {
  left: 11px
 } 

.horizontal-menu {
    box-sizing: border-box;
    background-color: #fff;
    /* height: 59px; */
    padding: 1%;
    padding-left: 3%;
    display: grid;
    align-items: center;
    box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 8vh;
}

.searchBar-bellIcon-container {
    display: flex;
    justify-content: space-between;
    color: var(--upcoming-event-color);
}   

.bell-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0% 4% 0% 4%;
}

.ham-container{
    margin: 0% 4%;
}

.ham-container-active{
    margin: 0% 4%;
    font-size: 20px;
    visibility: visible !important;  
}

.ham-container .fa-bars{
    font-size: 20px;
    color: var(--upcoming-event-color);
    /* visibility: hidden; */
}

.dashboard-container {
  padding: 1% 4%;
  width: auto !important;
}

.chart-wrapper {
  width: 97.5%;
  padding: 3%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.chart-wrapper canvas{
  height: 330px !important;
}

.sorter-div{

    text-align: right;
    color: #aaa;
}

.select-sort-value{

    border: none ;
    font-size: 16px;
    margin: 0% 0.5%;
    background-color: transparent;
    background-color: initial;
}

.events-display {
  width: 97.5% !important;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.upcoming-events-container {
  padding: 3%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  flex-basis: 48%;
}

.upcoming-events {
  display: flex;
  color: var(--upcoming-event-color);
}

.upcoming-events h3 {
  display: inline-block;
  margin-left: 2%;
}

.upcoming-events-list {
  margin-top: 5%;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin: 2% 0%;
  align-items: baseline;
  color: var(--dashboard-text-color-1);
}

.list-item small {
  font-size: 12px;
}

.list-item p {
  font-size: 14px;
}

.vector-line {
  height: 0.5px;
  flex-grow: 0;
  background-color: #c2cfe0;
}

.read-more{

  text-align: right;
  margin-top: 5%;
}

.read-more a{
  
  color: var(--dashboard-text-color-2);
}

.events-customers {
  flex-basis: 49%;
  display: flex;
  flex-direction: column;
}

.total-customers {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  margin-bottom: 2%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-2);
}

.total-events {
  display: flex;
  justify-content: space-between;
  padding: 5%;
  margin-top: 2%;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-2);
}

.events-customers small {
  color: var(--dashboard-text-color-1);
  font-size: 10px;
}

.right-side-items{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.griditems-link-wrapper {
  text-decoration: none;
  color: black;
}

.grid-right-items {
  width: 90% !important;
  margin-left: 8%;
  padding: 4% 9%;
  text-align: left;
  background-color: var(--dashboard-items-background);
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: var(--dashboard-text-color-1);
}

.grid-right-items p {
  margin: 1% 0%;
  font-size: 16px;
  line-height: 1.5;
}

.grid-right-items h3 {
  margin: 1% 0%;
  color: var(--dashboard-text-color-2);
  font-size: 24px;
  line-height: 1.3;
}

.grid-right-items small {
  font-size: 10px;
}

.right-item-value {
  color: var(--dashboard-text-color-2);
}

.up-arrow {
  color: var(--dashboard-up-arrow);
  margin: 1% 3%;
}

.down-arrow {
  color: var(--dashboard-down-arrow);
  margin: 1% 3%;
}


.calendar-wrapper {
  margin: 3% 4%;
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
}

.calendar-div {
  margin: 1% 4%;
  padding-bottom: 3%;
}

/* Buttons on top of calendar */

.events-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0% 3%;
  padding-top: 3%;
}

.events-buttons button {
  margin: 1%;
  padding: 0.5% 2%;
  background-color: var(--upcoming-event-color);
  border: none;
  font-weight: 600;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.events-buttons button:hover {
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--upcoming-event-color);
}

.button-1 {
  border: none;
  background-color: white !important;
  color: var(--upcoming-event-color) !important;
}

.button-1:hover {
  background-color: white;
  box-shadow: none !important;
}

/* Pop Up Modal CSS */

.popup-modal h2 {
  margin: 4% 6%;
}

.popup-title-div {
  display: flex;
  justify-content: space-between;
}

.popup-title-div span {
  margin: 2%;
  cursor: pointer;
  color: var(--sidebar-email-text-color);
}

.popup-form-wrapper {
  padding: 4%;
}

.popup-form {
  display: flex;
  flex-direction: column;
}

.textfield-wrapper {
  margin: 1% !important;
}

.textfield-wrapper label {
  margin: 2% 0% !important;
  color: var(--upcoming-event-color);
  font-weight: 600;
}

.textfield-input {
  padding: 3.5% 4%;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--input-border);
  background-color: var(--input-background);
}

.textfield-input:focus {
  outline: 2px solid var(--upcoming-event-color) !important;
}

.submit-button {
  margin: 2% 1%;
  padding: 3%;
  border-radius: 8px;
  border: none;
  background-color: var(--upcoming-event-color);
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 12px 0 rgba(55, 81, 255, 0.24);
}

.submit-button-wrapper{
  
  display: flex;
  align-items: center !important;
}

.MuiSlider-track {
  background-color: linear-gradient("#1eff00","#60f400","#bcc200","#cbb500","#ff3500","#ff0000") !important;
}

* {
  margin: 0%;
  padding: 0%;
}

.Pricing_Section_Wrapper {
  margin: 1%;
  padding: 0.5%;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  
}

.Switch_Link_container {
  display: flex;
}

.Pricing_and_Occupancy_Para1 {
  margin: 0%;
  font-family: "Poppins", sans-serif;
  color: #334d6e;
  border-bottom: 2px solid #109cf1;
}

.horizontal-line {
  margin: 0%;
  border: 0.5% solid #c6c6c6;
}

/* Filter Accordian */

.filter-section-accordian {
  margin: 2% 0%;
}

.accordian-content {
  display: flex;
  justify-content: space-between;
  margin: 1%;
  padding: 1%;
}

.period-selector {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.filter-section-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.filter-period-select {
  margin: 0% 8%;
}

.filter-section-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.KPI-select {
  width: 100%;
  margin: 10% 0% 0% 0%;
}

.button-group {
  margin: 0% 4%;
}

.apply-filter {
  margin: 2%;
  display: flex;
  justify-content: flex-end;
}

.filter-change-btn {
  margin: 0% 1% !important;
}

.Section2_Container {
  margin-top: 2%;
}
.Date_Range_Picker_wrapper {
  display: flex;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.Date_Range_Picker_container {
  display: flex;
  width: 75%;
  border-radius: 4px;
  background-color: #1565c0;
  color: white;
  padding: 2%;
  align-items: center;
  justify-content: center;
}

.date_Picker_input::-webkit-input-placeholder {
  color: white;
}

.date_Picker_input:-ms-input-placeholder {
  color: white;
}

.date_Picker_input::placeholder {
  color: white;
}

.date_Picker_input {
  width: 100%;
  font-size: 80%;
  border: none;
  background-color: #1565c0;
  color: white;
  font-family: "Poppins", sans-serif;
}

.All_Prices_container {
  align-items: center;
  margin: 1%;
}

.All_3_buttons_Container {
  margin: auto 0%;
}

.Action_btn_container {
  display: flex;
  grid-gap: 1%;
  gap: 1%;
  justify-content: flex-end;
  align-items: flex-end;
  flex-basis: 25%;
}

.option-price-selector{
  display: flex;
  align-items: center;
  margin: auto 0%;
  margin-right: 10%;
  width: 90%;
}

/* ------------ Table ----------- */
.table-grid-wrapper {
  color: var(--dashboard-text-color-1);
  width: 1150px;
  overflow-x: auto;
  font-size: 14px;
}

.table-grid {
  width: 150px;
}

.table-grid1 {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  width: 150px;
  background-color: whitesmoke;
}

.table-grid-column1 {
  padding: 10% 0%;
  padding-right: 10%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.table-grid-column2 {
  padding: 10% 0%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: center;
}

.table-grid-column {
  padding: 10% 0%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: center;
  display: grid;
  /* grid-template-columns: 1fr 0.6fr; */
  /* justify-content: center; */
}

.table-grid-column:hover {
  background-color: var(--upcoming-event-color);
  color: var(--dashboard-text-color-2);
  cursor: pointer;
}

.table-grid-column input {
  width: 80px;
  margin: auto;
  padding: 2% 4%;
}

.people{
  display: flex;
  justify-content: flex-end;
}

/* Bulk Update Popup CSS */

.bulk-update-modal{
  padding: 2% 4%;
}

.modal-title {
  margin-top: 1%;
  margin-left: 4%;
}

.period-weekdays {
  display: flex;
}

.period-inputs {
  margin: 4% 0%;
}

.period-inputs input {
  margin: 0% 2%;
  padding: 1%;
}

.room-groups h4 {
  margin: 1% 0%;
  margin-bottom: 3%;
}

.submit-cancel-btn {
  text-align: right;
  margin: 1% 1%;
}

.submit-cancel-btn button {
  margin: 0% 1%;
}

.submit-cancel-btn-2 {
  text-align: right;
  margin: 1% 1%;
}

.submit-cancel-btn-2 button {
  margin: 0% 1%;
}

.loader {
  display: flex;
  justify-content: center;
  margin: 20% 0%;
}

/* Individual price update modal */

.price-update-modal-inputs {
  display: flex;
  margin: 1% 0% !important;
}

.price-update-modal-inputs input {
  margin-left: 4%;
  padding: 1% 2%;
  border: none;
  outline: none;
  border-bottom: 1.5px solid rgb(75, 75, 75);
}

.price-update-modal-inputs input:focus {
  border-bottom: 1.5px solid var(--upcoming-event-color);
}

.price-update-table {
  padding: 4%;
  overflow-x: auto;
}

.table-title-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-title-div h4{
  flex-basis: 30%;
}

.table-title-select{
  flex-basis: 40%;
  display : flex;
  align-items: center;
}

.table-title-select h4{
  flex-basis: 60%;
}

@media (max-width: 600px) {
  .table-grid-wrapper {
    width: 300px;
  }

  .Section2_Container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .buttons-wrapper {
    margin-top: 5%;
    justify-content: center;
  }
  .accordian-content {
    flex-direction: column;
  }
  .filter-section-1 {
    flex-direction: column;
    margin: 2% 0%;
    justify-items: center;
  }
  .period-selector {
    margin: 2% auto;
  }

  .Date_Range_Picker_container {
    margin: auto;
  }

  .All_3_buttons_Container {
    box-shadow: none !important;
    justify-content: center;
  }

  .room-groups{
    overflow-x: scroll;
  }
}

@media (max-width: 424px) {
  .buttons-wrapper {
    flex-direction: column;
    justify-items: center;
  }
  .Action_btn {
    margin-top: 4% !important;
  }
}

@media (max-width: 1100px) {
.table-grid-wrapper {
  width: 1050px;
}
}

@media (min-width: 1600px) and (max-width:1700px){
  .table-grid-wrapper{
    width: 1306px;
  }
}

@media (min-width: 1700px) and (max-width:1800px){
  .table-grid-wrapper{
    width: 1406px;
  }
}

@media (min-width: 1800px) and (max-width:1900px){
  .table-grid-wrapper{
    width: 1606px;
  }
}

@media (min-width: 1900px) and (max-width:2000px){
  .table-grid-wrapper{
    width: 1706px;
  }
}
.settings-wrapper {
  padding: 4%;
}

.settings-card {
  background-color: white;
  width: 100%;
  padding: 2% 4%;
  margin: auto;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
}

.settings-title h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 1000;
  color: var(--dashboard-text-color-1);
}

.import-btn-section{

  text-align: right;
  margin: 1% 0%;
}

.import-btns button{

  margin-left: 2%;
}

.modal-section-loader{
  text-align: center;
  margin: 10% 0%;
}

.modal-section{
  padding: 4% 0%;
  display: flex;
  flex-wrap: wrap;
}

.modal-sheets{
  flex-basis: 50%;
  display: flex;
  margin: 1% 0%;
  align-items: center;
}

.modal-buttons{
  display: flex;
  justify-content: end;
  margin: 2% 0%;
}

.modal-buttons button{

  margin: 1% 2%;
}

.error-importig-sheets{

  margin: 10% 0%;
  text-align: center;
}
.hotel-inputs-container{

    margin: 2% 1% ;
    width: 85% !important;
}

.hotel-label-input-group{

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 1% 0% !important;
    color: var(--dashboard-text-color-1);
}

.hotel-label-input-group .MuiTextField-root{

    width: 35%;
}

.save-btn{

    background-color: rgb(1, 119, 1) !important;
    color: white !important;
    margin: 5% 0% !important;
}

.hotel-label-input-group .MuiInput-underline::after{

    border-bottom:2px solid var(--upcoming-event-color) !important;
}
.users-main-container {
  margin: 1% 0%;
}

.users-btn-container {
  margin: 1% 0% !important;
  display: flex;
  justify-content: flex-end !important;
}

.users-btns{

    margin: 0% 0.5% !important;
    background-color: var(--upcoming-event-color) !important;
    color: white !important;
}

.create-user-modal{

    padding: 4% !important;
}

.create-user-modal form{
    width: inherit;
}

.modal-title-container{

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% !important;
}

.modal-title-container h2{

    margin: 0%;
    color: var(--dashboard-text-color-1);
}

.create-user-input-container{

    margin: 2% 0%;
    color: var(--dashboard-text-color-1);
}

.create-user-input{

    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 1% 0% !important;
}

.create-user-input .MuiTextField-root{

    width: 45%;
}

.create-user-input .MuiInput-underline::after{

    border-bottom:2px solid var(--upcoming-event-color) !important;
}

.user-modal-btn-container{

    justify-content: right;
    margin: 4% 0% 1% 0% !important;
}

.user-modal-btn{

    margin: 0% 1% !important;
    color: white !important;
}

.user-modal-btn:first-child{

    border: 1px solid var(--upcoming-event-color) !important;
    color: #000 !important;
    color: initial !important;
}

.password-error{
    text-align: right;
}

@media (max-width:600px) {
    
    .create-user-modal-wrapper{

        width: 98% !important;
    }
}
.std-prices-date-picker{

    background-color: #1565c0;
    display: flex;
    color: white;
    width: 40%;
    padding: 1% 1%;
    margin: 1% 0%;
    border-radius: 10px;
}


@media (max-width:600px) {
    
    .std-prices-date-picker{

        width: 80%;
        margin: 1% auto;
    }
}
.PMS-key-interface{
    margin: 2% 0% 10% 0%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 50% 1 50%;
}

.PMS-key-interface label{
    margin-bottom: 2%;
}

.PMS-key-interface button{  
    margin: 2% 2% 2% 0%;
}

.pms-select-dropdown{
    display: flex;
}

.select-pms{
    width: 150px;
}

.pms-label{
    margin-right: 2%;
}

.PMS-key-interface-text{
    margin: 6% 0% 15% 0%;
    display: flex;
    flex-wrap: wrap;
}

.PMS-key-interface-text h6{

    font-size: inherit;
}

.key-edit{
    margin: 0% 5%;
    display: grid;
    text-align: right;
}

.key-edit i{
    font-size: 22px;
    text-align: right;
    cursor: pointer;
}

.pms form div:nth-child(2){
    text-align: right;
}
.events-table-wrapper {
  background-color: whitesmoke;
  padding: 3% 4%;
}

.table-container {
  padding: 2% 4%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
}

.btn-container {
  text-align: right;
}

.table-container button {
  margin: 1% 0%;
  font-weight: 600;
  border-radius: 20px;
}

.events-table-container {
  width: 100%;
}

.events-table-head {
  display: flex;
  justify-content: space-around;
  background-color: var(--table-head-row);
  color: white;
}

.table-data-items {
  flex-basis: 25%;
  text-align: center;
  padding: 1% 0%;
}

.events-table-data {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid var(--icon-color);
}

.login-wrapper {
  padding: 10%;
  background-color: var(--dashboard-text-color-1);
}

.login-card {
  background-color: white;
  padding: 2% 4% 2% 4%;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: auto;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
}

.login-card h1 {
  text-align: center;
  font-size: 72px;
  font-weight: bold;
  color: var(--upcoming-event-color);
}

.login-card h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 1000;
}

.login-inputs {
  margin: 6% 0%;
}

.login-input-label {
  margin: 1% 0% !important;
  color: var(--sidebar-email-text-color);
  font-weight: 500;
  font-size: 14px;
}

.password-labels{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  line-height: 1;
}

.forgot-pass-label{
  margin: 2% 0% !important;
  color: var(--sidebar-email-text-color);
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
}

.input-field {
  margin-bottom: 5% !important;
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid var(--input-border);
  background-color: var(--input-background);
}

.input-field input{

  padding: 14.5px 14px;
}

.input-field .Mui-focused fieldset{
  border-color: var(--upcoming-event-color) !important;
}

.login-button-wrapper p{

  text-align: center;
  color: var(--sidebar-email-text-color);
  cursor: pointer;
}

.login-button{
  width: 100%;    
  margin: 1% 0% 4% 0% !important;
  padding: 3% 3% !important;
  background-color: var(--upcoming-event-color) !important;
  color: white !important;
  box-shadow: 0 4px 12px 0 rgba(55, 81, 255, 0.24);
}

.login-button:hover{

  background-color: #0088dd !important;
}

.linear-progress{
  margin-bottom: -2%;
  position: relative;
  z-index: 1;
}

@media(max-width:768px){
  .login-card{
    width: 60%;
  }
}

@media(max-width:550px){
  .login-card{
    width: 100%;
  }
}
.error-msg{
    color: tomato;
}

.display-none{
    visibility: hidden;
}
