.std-prices-date-picker{

    background-color: #1565c0;
    display: flex;
    color: white;
    width: 40%;
    padding: 1% 1%;
    margin: 1% 0%;
    border-radius: 10px;
}


@media (max-width:600px) {
    
    .std-prices-date-picker{

        width: 80%;
        margin: 1% auto;
    }
}