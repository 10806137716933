@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://css.gg/user.css');

* {
  margin: 0%;
  padding: 0%;
}

.Pricing_Section_Wrapper {
  margin: 1%;
  padding: 0.5%;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  
}

.Switch_Link_container {
  display: flex;
}

.Pricing_and_Occupancy_Para1 {
  margin: 0%;
  font-family: "Poppins", sans-serif;
  color: #334d6e;
  border-bottom: 2px solid #109cf1;
}

.horizontal-line {
  margin: 0%;
  border: 0.5% solid #c6c6c6;
}

/* Filter Accordian */

.filter-section-accordian {
  margin: 2% 0%;
}

.accordian-content {
  display: flex;
  justify-content: space-between;
  margin: 1%;
  padding: 1%;
}

.period-selector {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.filter-section-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.filter-period-select {
  margin: 0% 8%;
}

.filter-section-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.KPI-select {
  width: 100%;
  margin: 10% 0% 0% 0%;
}

.button-group {
  margin: 0% 4%;
}

.apply-filter {
  margin: 2%;
  display: flex;
  justify-content: flex-end;
}

.filter-change-btn {
  margin: 0% 1% !important;
}

.Section2_Container {
  margin-top: 2%;
}
.Date_Range_Picker_wrapper {
  display: flex;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.Date_Range_Picker_container {
  display: flex;
  width: 75%;
  border-radius: 4px;
  background-color: #1565c0;
  color: white;
  padding: 2%;
  align-items: center;
  justify-content: center;
}

.date_Picker_input::placeholder {
  color: white;
}

.date_Picker_input {
  width: 100%;
  font-size: 80%;
  border: none;
  background-color: #1565c0;
  color: white;
  font-family: "Poppins", sans-serif;
}

.All_Prices_container {
  align-items: center;
  margin: 1%;
}

.All_3_buttons_Container {
  margin: auto 0%;
}

.Action_btn_container {
  display: flex;
  gap: 1%;
  justify-content: flex-end;
  align-items: flex-end;
  flex-basis: 25%;
}

.option-price-selector{
  display: flex;
  align-items: center;
  margin: auto 0%;
  margin-right: 10%;
  width: 90%;
}

/* ------------ Table ----------- */
.table-grid-wrapper {
  color: var(--dashboard-text-color-1);
  width: 1150px;
  overflow-x: auto;
  font-size: 14px;
}

.table-grid {
  width: 150px;
}

.table-grid1 {
  position: sticky;
  left: 0;
  width: 150px;
  background-color: whitesmoke;
}

.table-grid-column1 {
  padding: 10% 0%;
  padding-right: 10%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.table-grid-column2 {
  padding: 10% 0%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: center;
}

.table-grid-column {
  padding: 10% 0%;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  text-align: center;
  display: grid;
  /* grid-template-columns: 1fr 0.6fr; */
  /* justify-content: center; */
}

.table-grid-column:hover {
  background-color: var(--upcoming-event-color);
  color: var(--dashboard-text-color-2);
  cursor: pointer;
}

.table-grid-column input {
  width: 80px;
  margin: auto;
  padding: 2% 4%;
}

.people{
  display: flex;
  justify-content: flex-end;
}

/* Bulk Update Popup CSS */

.bulk-update-modal{
  padding: 2% 4%;
}

.modal-title {
  margin-top: 1%;
  margin-left: 4%;
}

.period-weekdays {
  display: flex;
}

.period-inputs {
  margin: 4% 0%;
}

.period-inputs input {
  margin: 0% 2%;
  padding: 1%;
}

.room-groups h4 {
  margin: 1% 0%;
  margin-bottom: 3%;
}

.submit-cancel-btn {
  text-align: right;
  margin: 1% 1%;
}

.submit-cancel-btn button {
  margin: 0% 1%;
}

.submit-cancel-btn-2 {
  text-align: right;
  margin: 1% 1%;
}

.submit-cancel-btn-2 button {
  margin: 0% 1%;
}

.loader {
  display: flex;
  justify-content: center;
  margin: 20% 0%;
}

/* Individual price update modal */

.price-update-modal-inputs {
  display: flex;
  margin: 1% 0% !important;
}

.price-update-modal-inputs input {
  margin-left: 4%;
  padding: 1% 2%;
  border: none;
  outline: none;
  border-bottom: 1.5px solid rgb(75, 75, 75);
}

.price-update-modal-inputs input:focus {
  border-bottom: 1.5px solid var(--upcoming-event-color);
}

.price-update-table {
  padding: 4%;
  overflow-x: auto;
}

.table-title-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table-title-div h4{
  flex-basis: 30%;
}

.table-title-select{
  flex-basis: 40%;
  display : flex;
  align-items: center;
}

.table-title-select h4{
  flex-basis: 60%;
}

@media (max-width: 600px) {
  .table-grid-wrapper {
    width: 300px;
  }

  .Section2_Container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .buttons-wrapper {
    margin-top: 5%;
    justify-content: center;
  }
  .accordian-content {
    flex-direction: column;
  }
  .filter-section-1 {
    flex-direction: column;
    margin: 2% 0%;
    justify-items: center;
  }
  .period-selector {
    margin: 2% auto;
  }

  .Date_Range_Picker_container {
    margin: auto;
  }

  .All_3_buttons_Container {
    box-shadow: none !important;
    justify-content: center;
  }

  .room-groups{
    overflow-x: scroll;
  }
}

@media (max-width: 424px) {
  .buttons-wrapper {
    flex-direction: column;
    justify-items: center;
  }
  .Action_btn {
    margin-top: 4% !important;
  }
}

@media (max-width: 1100px) {
.table-grid-wrapper {
  width: 1050px;
}
}

@media (min-width: 1600px) and (max-width:1700px){
  .table-grid-wrapper{
    width: 1306px;
  }
}

@media (min-width: 1700px) and (max-width:1800px){
  .table-grid-wrapper{
    width: 1406px;
  }
}

@media (min-width: 1800px) and (max-width:1900px){
  .table-grid-wrapper{
    width: 1606px;
  }
}

@media (min-width: 1900px) and (max-width:2000px){
  .table-grid-wrapper{
    width: 1706px;
  }
}