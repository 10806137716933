.users-main-container {
  margin: 1% 0%;
}

.users-btn-container {
  margin: 1% 0% !important;
  display: flex;
  justify-content: flex-end !important;
}

.users-btns{

    margin: 0% 0.5% !important;
    background-color: var(--upcoming-event-color) !important;
    color: white !important;
}

.create-user-modal{

    padding: 4% !important;
}

.create-user-modal form{
    width: inherit;
}

.modal-title-container{

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% !important;
}

.modal-title-container h2{

    margin: 0%;
    color: var(--dashboard-text-color-1);
}

.create-user-input-container{

    margin: 2% 0%;
    color: var(--dashboard-text-color-1);
}

.create-user-input{

    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 1% 0% !important;
}

.create-user-input .MuiTextField-root{

    width: 45%;
}

.create-user-input .MuiInput-underline::after{

    border-bottom:2px solid var(--upcoming-event-color) !important;
}

.user-modal-btn-container{

    justify-content: right;
    margin: 4% 0% 1% 0% !important;
}

.user-modal-btn{

    margin: 0% 1% !important;
    color: white !important;
}

.user-modal-btn:first-child{

    border: 1px solid var(--upcoming-event-color) !important;
    color: initial !important;
}

.password-error{
    text-align: right;
}

@media (max-width:600px) {
    
    .create-user-modal-wrapper{

        width: 98% !important;
    }
}