.PMS-key-interface{
    margin: 2% 0% 10% 0%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 50% 50%;
}

.PMS-key-interface label{
    margin-bottom: 2%;
}

.PMS-key-interface button{  
    margin: 2% 2% 2% 0%;
}

.pms-select-dropdown{
    display: flex;
}

.select-pms{
    width: 150px;
}

.pms-label{
    margin-right: 2%;
}

.PMS-key-interface-text{
    margin: 6% 0% 15% 0%;
    display: flex;
    flex-wrap: wrap;
}

.PMS-key-interface-text h6{

    font-size: inherit;
}

.key-edit{
    margin: 0% 5%;
    display: grid;
    text-align: right;
}

.key-edit i{
    font-size: 22px;
    text-align: right;
    cursor: pointer;
}

.pms form div:nth-child(2){
    text-align: right;
}