.events-table-wrapper {
  background-color: whitesmoke;
  padding: 3% 4%;
}

.table-container {
  padding: 2% 4%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 7.2px 21.5px 0 rgba(0, 0, 0, 0.06);
}

.btn-container {
  text-align: right;
}

.table-container button {
  margin: 1% 0%;
  font-weight: 600;
  border-radius: 20px;
}

.events-table-container {
  width: 100%;
}

.events-table-head {
  display: flex;
  justify-content: space-around;
  background-color: var(--table-head-row);
  color: white;
}

.table-data-items {
  flex-basis: 25%;
  text-align: center;
  padding: 1% 0%;
}

.events-table-data {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid var(--icon-color);
}
