
.horizontal-menu {
    box-sizing: border-box;
    background-color: #fff;
    /* height: 59px; */
    padding: 1%;
    padding-left: 3%;
    display: grid;
    align-items: center;
    box-shadow: 6px 0 18px 0 rgba(0, 0, 0, 0.06);
    position: sticky;
    top: 0;
    z-index: 1;
    height: 8vh;
}

.searchBar-bellIcon-container {
    display: flex;
    justify-content: space-between;
    color: var(--upcoming-event-color);
}   

.bell-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0% 4% 0% 4%;
}

.ham-container{
    margin: 0% 4%;
}

.ham-container-active{
    margin: 0% 4%;
    font-size: 20px;
    visibility: visible !important;  
}

.ham-container .fa-bars{
    font-size: 20px;
    color: var(--upcoming-event-color);
    /* visibility: hidden; */
}
