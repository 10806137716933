
/* hamburger and notification bar container Media Queries */

@media(max-width:880px){

    .ham-container .fa-bars{

        visibility: visible !important;
    }

    .searchBar-bellIcon-container{

        margin: 1% 0%;
    }
}

/* Chart and Events Media Queries */

@media (max-width: 1100px) {
  .dashboard-container {
    flex-direction: column;
  }

  .right-side-items {
    max-width: 100% !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3% !important;
    align-items: center;
  }
  .grid-right-items {
    margin-left: 0%;
    margin: auto;
  }

  .chart-event-holder {
    width: 100% !important;
  }

  .chart-wrapper {
    margin: auto;
  }
  .events-display {
    margin: auto;
    margin-top: 4%;
  }
}

@media (min-width: 600px) {
  .chart-event-holder {
    max-width: 100% !important;
  }
}

@media (max-width: 430px) {
  .right-side-items {
    grid-template-columns: 1fr 1fr;
  }
  .events-display {
    display: grid;
    grid-template-columns: 1fr;
  }
  .events-customers {
    margin: 4% 0%;
  }
}
