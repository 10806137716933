.hotel-inputs-container{

    margin: 2% 1% ;
    width: 85% !important;
}

.hotel-label-input-group{

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 1% 0% !important;
    color: var(--dashboard-text-color-1);
}

.hotel-label-input-group .MuiTextField-root{

    width: 35%;
}

.save-btn{

    background-color: rgb(1, 119, 1) !important;
    color: white !important;
    margin: 5% 0% !important;
}

.hotel-label-input-group .MuiInput-underline::after{

    border-bottom:2px solid var(--upcoming-event-color) !important;
}